<template>
    <div class="enrollement-form">
        <b-form class="custom-fuel-form">
            <div class="lang">
                <span v-for="(lang, key) in langs" :key=key :class="{active : $root.$i18n.locale != key}" @click="changeLang(key)">{{lang}}</span>
            </div>

            <h4>{{$t('accountInformations')}}</h4>
            <b-col cols="6" class="custom-form-group">
                <label for="firstname">{{$t('firstname')}} * :</label>
                <b-form-input id="firstname" ref="firstname" state="null" class="custom-input" v-model="form.firstName"
                    type="text" required/>
            </b-col>

            <b-col cols="6" class="custom-form-group">
                <label for="lastname">{{$t('lastname')}} * :</label>
                <b-form-input id="lastname" ref="lastname" state="null" class="custom-input" v-model="form.lastName"
                    type="text" />
            </b-col>

            <b-col cols="12" class="custom-form-group">
                <label for="email">{{$t('email')}} * :</label>
                <b-form-input id="email" ref="email" state="null" class="custom-input" v-model="form.email"
                    type="email" />
            </b-col>

            <b-col cols="12" class="custom-form-group">
                <label for="plate">{{$t('plate')}} :</label>
                <div class="plate">
                    <input id="plate" autocapitalize=characters v-model="formattedPlate" type="text"
                        class="custom-input form-control" maxlength="9" disabled>
                </div>
            </b-col>

            <b-col cols="12" class="custom-form-group">
                <label for="cp">{{$t('fuel')}} :</label>
                <b-form-select class="custom-select" v-model="form.favoriteFuel" :options="fuels" disabled></b-form-select>
            </b-col>

            <b-col cols="12" class="custom-form-group">
                <label for="address">{{$t('address')}} :</label>
                <b-form-input id="address" ref="address" state="null" class="custom-input" v-model="form.address"
                    type="text" />
            </b-col>

            <b-col cols="6" class="custom-form-group">
                <label for="city">{{$t('city')}} :</label>
                <b-form-input id="city" ref="city" state="null" class="custom-input" v-model="form.city" type="text" />
            </b-col>

            <b-col cols="6" class="custom-form-group">
                <label for="country">{{$t('country')}} :</label>
                <b-form-input id="country" ref="country" state="null" class="custom-input" v-model="form.country"
                    type="text" />
            </b-col>
            <b-col cols="12" class="custom-form-group">
                <b-form-checkbox id="checkbox-1" checked v-model="form.enrollCard" class="custom-checkbox" disabled>
                    {{$t("enrollMyCard")}}
                </b-form-checkbox>
            </b-col>
            <div class="form-footer">
                <b-button type="submit" class="btn-active" variant="primary" @click="save" :disabled="saveButtonDisabled">{{$t('save')}}</b-button>
            </div>
        </b-form>
    </div>
</template>

<script>
    var MQTT = require("@/assets/js/Fuel/MqttService");
    var Customer = require("@/assets/js/Customer");
    var Stats = require("@/assets/js/Stats");
    import { sleep, clearCacheExceptCustomer, isNotNull } from "@/assets/js/Utils"

    export default {
        name: 'EnrollementForm',
        data() {
            return {
                form: {
                    token: localStorage.getItem("DKC_fuel_token") || null,
                    firstName: "",
                    lastName: "",
                    email: "",
                    plate: "AH567FT",
                    cp: "59350",
                    address: "21 Rue de La Pointe",
                    city: "Seclin",
                    country: "FRANCE",
                    favoriteFuel: this.$route.query.f || "d_pre",
                    loyaltyCardNumber: null,
                    allergens: null,
                    enrollCard : true,
                },
                fuels: [
                    { text: 'Super Premier', value: 's_pre' },
                    { text: 'Super Premium', value: 's_ex' },
                    { text: 'Diesel Premier', value: 'd_pre' },
                    { text: 'Diesel Premium', value: 'd_ex' }
                ],
                newTab : null,
                saveButtonDisabled : true,
                lang: "en",
            }
        },
        mounted(){
            clearCacheExceptCustomer();

            MQTT.publishMessage('ENROLL');


            localStorage.setItem("DKC_fuel_loyalty", this.$route.query.l || 0);

            MQTT.subscribe("READY_TO_ENROLL", () => {
               this.saveButtonDisabled = false;
            })
            
        },
        methods: {
            save(e) {
                e.preventDefault();

                if(this.form.firstName != "" && this.form.lastName != "" && this.form.email != ""){
                    localStorage.setItem("DKC_fuel_user_favoriteFuel", this.form.favoriteFuel);
                    localStorage.setItem("DKC_lang", this.lang);
                    this.$parent.loader.showLoader = true;
                    this.$parent.loader.content = this.$t("authenticationLoadingText");
                    setTimeout(()=>{
                        sleep(1000)
                    }, 1000);
                    Customer.registerCustomer(this.form.firstName, this.form.lastName);
                    this.form.token = localStorage.getItem("DKC_fuel_token");
                    Customer.updateCustomerLoyaltyPoints(this.$route.query.l);
                    Customer.updateCustomer(this.form);
                    MQTT.publishMessage('LOYALTY_UPDATE', JSON.stringify({LoyaltyPoints : this.$route.query.l}));

                    localStorage.setItem("DKC_email", this.form.email)

                    this.setStats();

                    this.newTab = window.open(this.$parent.base + "/3dsecure?enroll=true");
                    this.newTab.addEventListener('pagehide', (e)=>{
                        if(e.target.URL != "about:blank"){
                            this.$parent.loader.content = this.$t("loadingText");
                            let mqttDataToSend = {
                                FirstName : this.form.firstName,
                                LastName : this.form.lastName,
                                FuelType : this.form.favoriteFuel
                            };
                            
                            MQTT.publishMessage('ENROLL_END', JSON.stringify(mqttDataToSend), ()=>{
                                setTimeout(()=>{
                                    this.$parent.loader.showLoader = false;
                                    this.$parent.loader.content = "";
                                    this.$router.push({path: this.$parent.base + "/"})
                                }, 3000)
                            });
                            
                        }
                    })
                }else{
                    this.$parent.showAlert(this.$t("errorUpdateCustomer"), "error")
                }
            },
            changeLang(newLang){
                if(!Object.keys(this.langs).includes(newLang))
                    newLang = "en";

                this.$root.$i18n.locale = newLang;
                this.lang = newLang;
                this.showLangPopover = false;
            },
            setStats(){
                var complexId = localStorage.getItem("DKC_complexId");
                var complexIdParent = localStorage.getItem("DKC_complexIdParent");
                var deviceType = localStorage.getItem("DKC_deviceType");
                var parameters = { nbPlayedDemos: 1 }

                if(isNotNull(complexIdParent) && isNotNull(complexId) && complexIdParent != complexId)
                    parameters.complexIdParent = complexIdParent;

                if(isNotNull(deviceType))
                    parameters.deviceType = deviceType;

                Stats.setStats(parameters);
            }
        },
        computed:{
            formattedPlate(){
                let formattedPlate = this.form.plate.toUpperCase();
                formattedPlate = formattedPlate.slice(0, 2) + "-" + formattedPlate.slice(2, 5) + "-" + formattedPlate.slice(5)
                
                return formattedPlate.substring(0,9)
            }
        }
    }
</script>

<style>
    
</style>